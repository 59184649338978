<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <span style="margin-right: 20px">业务编号：{{ form.serial_num }}</span>
        <a-button>
          <router-link :to="{ name: 'CumsOutList' }">取消</router-link>
        </a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
      </div>
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="6">
            <a-form-model-item label="客户名称" prop="customer_id" :autoLink="false">
              <!-- <a-select
                showSearch
                label-in-value
                allowClear
                ref="customer"
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select> -->
              <a-input disabled v-model="form.agency_name" class="full-width" />
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item label="客户联系人">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择客户联系人"
                style="width: 100%"
                ref="customer_contact"
                v-model="form.customer_contact_id"
              >
                <a-select-option v-for="op in customerContactOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="客户编号">
              <a-input v-model="form.customer_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="业务类型">
              <a-select showSearch allowClear placeholder="请选择业务类型" style="width: 100%" v-model="form.type">
                <a-select-option v-for="op in bizTypeOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <!-- <template>
            <a-col :span="6">
              <a-form-model-item label="清关编号">
                <a-input v-model="form.clear_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="业务编号">
                <a-input v-model="form.operate_num" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="出仓编号">
                <a-input v-model="form.out_num" />
              </a-form-model-item>
            </a-col>
          </template> -->
          <a-col :span="6">
            <a-form-model-item label="操作日期" prop="box_time">
              <a-date-picker v-model="form.operate_date" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="出库日期" prop="box_time">
              <a-date-picker v-model="form.export_date" class="full-width" disabled></a-date-picker>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item label="箱型">
              <a-input-group compact>
                <a-select
                  showSearch
                  :filter-option="filterOption"
                  allowClear
                  placeholder="请选择箱型"
                  style="width: 60%"
                  v-model="form.box_id"
                >
                  <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
                <a-input-number v-model="form.box_data_list[0]['num']" style="width: 25%" />
                <a-button type="link" style="width: 15%" @click="createMoreBox">更多</a-button>
              </a-input-group>
            </a-form-model-item>
          </a-col> -->
        </a-row>
        <!-- <a-divider orientation="left" dashed>更多箱型</a-divider> -->
        <!-- <a-row :gutter="20" v-if="form.box_data_list.length > 1">
          <template v-for="(box, index) in form.box_data_list">
            <a-col :span="4" style="width: 20%" :key="index" v-if="index > 0">
              <a-form-model-item>
                <a-input-group compact>
                  <a-select
                    showSearch
                    :filter-option="filterOption"
                    allowClear
                    placeholder="请选择箱型"
                    style="width: 60%"
                    v-model="box.id"
                  >
                    <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                  <a-input-number v-model="box.num" style="width: 25%" />
                  <a-button type="link" style="width: 15%" @click="delMoreBox(index)">删除</a-button>
                </a-input-group>
              </a-form-model-item>
            </a-col>
          </template>
        </a-row> -->
        <a-row :gutter="20">
          <!-- <a-col :span="6">
            <a-form-model-item label="箱号">
              <a-input v-model="form.box_no" />
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6">
            <a-form-model-item label="操作人员">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择操作人员"
                style="width: 100%"
                ref="tally_id"
                v-model="form.operate_person"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="理货员">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择理货员"
                style="width: 100%"
                ref="arrange_man"
                v-model="form.arrange_man_id"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="出库总立方">
              <a-input-number v-model="form.out_cube" class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="出库操作备注">
              <a-textarea disabled v-model="form.operate_remark" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-textarea disabled v-model="form.out_warehouse_remark" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="反馈备注">
              <a-textarea v-model="form.feedback_remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <a-card class="card" title="其它信息" :bordered="false">
      <edit-table
        scroll
        :operate="{ add: false, del: false }"
        :columns="cumsGoodsColumns"
        :sourceData="cumsGoodsData"
        @getTableDate="getCumsGoodsData"
      ></edit-table>
    </a-card>
  </div>
</template>

<script>
import { getCommonOptions } from '@/api/common'
import {
  getWmsDataOption,
  updateWmsOutBusinessInfo,
  getWmsBusinsessDetail
} from '@/api/wms'
import { EditTable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick'
// import debounce from 'lodash/debounce';
export default {
  components: {
    EditTable, preventReClick
  },
  data() {
    // this.lastFetchId = 0;
    // this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      disabled: false,
      form: {},
      sourceForm: {
        id: null,
        serial_num: '',
        agency_name: '',
        operate_date: moment(),
        operate_person: null,
        arrange_man_id: null,
        out_cube: undefined,
        remark: '',
        feedback_remark: ''
      },
      // bizTypeOps: [],
      // customerOps: [],
      // customerInfo: undefined,
      // fetching: false,
      // customerContactOps: [],
      // boxOps: [], // 箱型
      cumsGoodsData: [],
      operatorOps: [],
      rules: {
        // customer_id: [{ required: true, message: '请选择客户名称', trigger: 'change' }],
        // box_time: [{ required: true, message: '请选择装箱日期', trigger: 'change' }]
      },
      cumsGoodsColumns: [
        {
          title: '出库核注清单号',
          dataIndex: 'check_num',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 120,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '商品规格型号',
          dataIndex: 'good_spec',
          width: 120,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 80,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 80,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 120,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '申报数',
          dataIndex: 'upload_num',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 120,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '毛重',
          dataIndex: 'gloss_weight',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '净重',
          dataIndex: 'weight',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        },
        {
          title: '库位',
          dataIndex: 'stock_place',
          width: 100,
          extra: {
            disabled: record => {
              return true
            }
          }
        }
      ]
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'CumsOutCUR') {
        // 编辑页路由监控
        this.handleFormReset()
        this.getEditData(newRoute.params.id)
      }
    }
  },
  created() {
    this.handleFormReset()
    this.getFormBasicData()
    this.getEditData(this.$route.params.id)
  },
  methods: {
    getWmsDataOption,
    getCommonOptions,
    moment,
    handleFormReset() {
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      // this.customerInfo = undefined
      this.cumsGoodsData = []
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getEditData(id) {
      this.$route.meta.title = '客服出库'
      getWmsBusinsessDetail('out', { id: id }).then(res => {
        const record = res.record
        record['warehouse_operate_date'] = record['warehouse_operate_date'] ? moment(record['warehouse_operate_date'], 'YYYY/MM/DD') : null
        this.form = {
          ...record,
          operate_date: record.warehouse_operate_date,
          operate_person: record.warehouse_operate_person ? record.warehouse_operate_person : null,
          arrange_man_id: record.warehouse_arrange_person ? record.warehouse_arrange_person : null,
          out_cube: record.warehouse_out_cube,
          feedback_remark: record.feed_back,
          out_warehouse_remark: record.remark,
          export_date: record.export_date
        }
        this.cumsGoodsData = res.cums_good_list
      })
    },
    // getDepartmentCustomer(value) {
    //   this.lastFetchId += 1;
    //   const fetchId = this.lastFetchId;
    //   this.customerOps = [];
    //   this.fetching = true;
    //   getCustomer({
    //     department: 1,
    //     customerName: value
    //   }).then(res => {
    //     if (fetchId !== this.lastFetchId) {
    //       return;
    //     }
    //     this.customerOps = res
    //     this.fetching = false;
    //   })
    // },
    // changeCustomerInfo(value) {
    //   this.customerInfo = value
    //   this.queryParam['customer_id'] = value.key
    //   this.customerOps = []
    //   this.fetching = false
    // },
    getFormBasicData() {
      // getCommonOptions('box_info').then(v => {
      //   this.boxOps = v
      // })
      getWmsDataOption('operator').then(v => {
        this.operatorOps = v
      })
      // getWmsMetaOption('wms_business_type').then(v => {
      //   this.bizTypeOps = v
      // })
    },
    // createMoreBox() {
    //   // 增加更多箱型
    //   if (this.form.box_data_list.length === 1) {
    //     for (let i = 0; i < 10; i++) {
    //       this.form.box_data_list.push({ id: null, num: null })
    //     }
    //   } else {
    //     this.form.box_data_list.splice(1)
    //   }
    // },
    // delMoreBox(index) {
    //   this.form.box_data_list.splice(index, 1)
    // },
    getCumsGoodsData(data) {
      this.cumsGoodsData = data
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          updateWmsOutBusinessInfo({
              ...this.form,
              operate_date: this.form.operate_date ? moment(this.form.operate_date).valueOf() : null
            }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '编辑成功'
            })
            this.$router.push({
              name: 'CumsOutList'
            })
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style>
.red .ant-table-column-title {
  color: red;
}
</style>
<style scoped>
.sub-model {
  width: 13%;
  margin-right: 1%;
}

.sub-model-title {
  position: relative;
  padding: 0 11px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-right: 0;
}

.sub-model .ant-input-number {
  width: calc(100% - 55px);
}
</style>
